import React, { Suspense } from 'react'
import { Outlet, Navigate } from 'react-router-dom'  
import { useSelector, useDispatch } from 'react-redux'
import Logo from '../../assets/img/logo-with-text.png'
import { Canvas } from '@react-three/fiber'
import {
  Stats,
  OrbitControls,
  Environment,
  useGLTF,
  Clone
} from '@react-three/drei'
import { useControls } from 'leva'

import ModalUrl from '../../assets/modal/earth.glb'

function Model() {
  const { scene } = useGLTF(ModalUrl)
  return <Clone object={scene} autoRotate />
}

export default function Auth() {
  const { isLogged } = useSelector((state) => state.auth)
  // console.log(isLogged);
  if (isLogged) {
    return <Navigate to='/app/dashboard' replace />;
  }

  return (
    <div className='auth-layout'>
      <div className="bg-animation">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <div id="stars4"></div>
      </div>
      <img src={Logo} className='logo' alt="NL - Sendinel" />
      <div style={{ width: "90vw", height: "100vh" }}>
        <Canvas camera={{ fov: 70, position: [-2, 0, -0.2], near: 0.025 }}>
          <Environment
            // files="https://cdn.jsdelivr.net/gh/Sean-Bradley/React-Three-Fiber-Boilerplate@useGLTF/public/img/workshop_1k.hdr"
            preset="night"
            // background
          />
          <Suspense>
            <Model />
          </Suspense>
          <OrbitControls autoRotate autoRotateSpeed={0.4} />
          <Stats />
        </Canvas>
      </div>
      <div className="auth-card">
        <Outlet />
      </div>
    </div>
  )
}
