import React, { useState, useEffect, useRef } from 'react'
import { Select, Tooltip, Popconfirm, Radio, Popover, Modal, Checkbox, Skeleton } from 'antd'
import Draggable from 'react-draggable'

import DocFront from '../assets/img/doc-front.jpg'
import DocBack from '../assets/img/doc-back.jpg'

export default function DocController(props) {
  let docData = props?.docData
  const [imageLoading, setImageLoading] = useState(false);
  const docRef = useRef()
  var currentUrl = window.location.href;

  const [imgStyle, setImgStyle] = useState({rotate: 0, zoom: 1, flip: false})
  const [docLink, setDocLink] = useState('https://guardian-northlark.com/task/doc?id=23s42sd73dg98');
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    pauseOrResume()
  }, [])
  
  const [time, setTime] = useState({
    sec: 0,
    min: 0,
    hr: 0
  });

  const [intervalId, setIntervalId] = useState();

  const updateTimer = () => {
    setTime((prev) => {
      let newTime = { ...prev };
      // update sec and see if we need to increase min
      if (newTime.sec < 59) newTime.sec += 1;
      else {
        newTime.min += 1;
        newTime.sec = 0;
      }
      // min has increased in *newTime* by now if it was updated, see if it has crossed 59
      if (newTime.min === 60) {
        newTime.min = 0;
        newTime.hr += 1;
      }

      return newTime;
    });
  };

  const pauseOrResume = () => {
    if (!intervalId) {
      let id = setInterval(updateTimer, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
      setIntervalId("");
    }
  };

  const reset = () => {
    clearInterval(intervalId);
    setTime({
      sec: 0,
      min: 0,
      hr: 0
    });
  }; 

  function editImg(action) {
    let style = {...imgStyle}
    switch (action) {
        case 'rorateX':
            style['rotate'] -= 45
            break;
        case 'rorateY':
            style['rotate'] += 45
            break;
        case 'zoomIn':
            if (style['zoom'] !== 10) {
                style['zoom'] += 0.5
            }
            break;
        case 'zoomOut':
            if (style['zoom'] !== 0.5) {
                style['zoom'] -= 0.5
            }
            break;
        case 'flip':
            style['flip'] = !style['flip']
            break;
    }

    setImgStyle(style)
  }

  const copyDocLink = () => {
    navigator.clipboard.writeText(currentUrl)
    setIsCopied(true)
  }

  return (
    <div>
      <div className="task-header">
          <h4>Simman - DL Extraction</h4>
          <h4>Priority</h4>
      </div>
      <div className="task-card">
              <p className='task-timer'>{`${time.hr < 10 ? 0 : ""}${time.hr}:${time.min < 10 ? 0 : ""}${time.min}:${time.sec < 10 ? 0 : ""}${time.sec}`}</p>
              <div className='process-count'>
                  <p>Process Count:</p>
                  <h6>02</h6>
              </div>
              <div className="w-75 mx-auto px-4 text-center">
                  <div className="data-list">
                      <h6><span>Country:</span> {docData?.issue_country ?? 'India'}</h6>
                      <h6><span>Document Type:</span> {docData?.document_type ?? 'Aadhar'}</h6>
                      <h6><span>Issuing State/Region:</span> {docData?.region ?? 'Tamil Nadu'}</h6>
                  </div>
                  <Draggable position={null}>
                      <div>
                          {imageLoading ? 
                            <Skeleton.Image active className='custom-image-loader bg-white' />
                            :
                            <div ref={docRef} className={`doc-img ${imgStyle.flip ? 'flip' : ''}`} style={{rotate: `${imgStyle.rotate}deg`, transform: `scale(${imgStyle.zoom})`}}>
                                {/* <img className='back' src={`data:image/jpeg;base64,${docData?.image_back}`} alt="" />
                                <img className='front' src={`data:image/jpeg;base64,${docData?.image_front}`} alt="" /> */}
                                <img className='back' src={DocBack} alt="" />
                                <img className='front' src={DocFront} alt="" />
                            </div>
                          }
                      </div>
                  </Draggable>
                  <div className="options">
                      <div className="img-options">
                          <button onClick={()=>editImg('rorateX')}><i className="fas fa-undo"></i></button>
                          <button onClick={()=>editImg('zoomIn')}><i className="far fa-search-plus"></i></button>
                          <button onClick={()=>editImg('flip')}><i className="far fa-window-restore"></i></button>
                          <button onClick={()=>editImg('zoomOut')}><i className="far fa-search-minus"></i></button>
                          <button onClick={()=>editImg('rorateY')}><i className="fas fa-redo-alt"></i></button>
                      </div>
                      <div className='doc-link'>
                          <p>{currentUrl}</p>
                          <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
                            <button onClick={copyDocLink}><i className={`${isCopied ? 'fad' : 'far'} fa-copy`}></i></button>
                          </Tooltip>
                      </div>
                  </div>
              </div>
      </div>
    </div>
  )
}
