import React, {useState, useEffect, useRef } from 'react'
import { Select, Tooltip, Popconfirm, Radio, Popover, Modal, Checkbox, Skeleton } from 'antd'
import Draggable from 'react-draggable'
import { useNavigate, useParams } from 'react-router-dom';

import Logo from '../assets/img/logo-with-text.png'

import SampleDocFront from '../assets/img/sample-doc-front.jpg'
import SampleDocBack from '../assets/img/sample-doc-back.jpg'

import DocFront from '../assets/img/doc-front.jpg'
import DocBack from '../assets/img/doc-back.jpg'

import Doc2012 from '../assets/img/versions/2012.jpg'
import Doc2020 from '../assets/img/versions/2020.jpg'

import { sendNotify } from '../helper/notification';

import TrackRep from '../repository/TrackRep';

export default function AddressEx() {
    const { id } = useParams();
    const docRef = useRef()
    const sampleDocRef = useRef()
    const navigate = useNavigate();
    var currentUrl = window.location.href;

    const [docData, setDocData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    
    const [imgStyle, setImgStyle] = useState({rotate: 0, zoom: 1, flip: false})
    const [sampleImgStyle, setSampleImgStyle] = useState({rotate: 0, zoom: 1, flip: false})
    const [docLink, setDocLink] = useState('https://guardian-northlark.com/task/doc?id=23s42sd73dg98');
    const [isCopied, setIsCopied] = useState(false);
    const [formData, setFormData] = useState({});
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [disableId, setDisableId] = useState(0);

    const [selectedFlags, setSelectedFlags] = useState([]);
    const [selectedDocVersion, setSelectedDocVersion] = useState(0);

    const [selectConclusive, setSelectConclusive] = useState([]);
    const [selectODP, setSelectODP] = useState('');

    const [showComment, setShowComment] = useState(false);
    const [comment, setComment] = useState('');

    const [unsupported, setUnsupported] = useState(null)
    const [iqFail, setIqFail] = useState(null)
    const [language, setLanguage] = useState(null)
    const [escalate, setEscalate] = useState(null)

    const [ccv, setCcv] = useState(null)

    useEffect(() => {
        // console.log(id, status);
        getImages(id)
      }, []);

      const [time, setTime] = useState({
        sec: 0,
        min: 0,
        hr: 0
    });
    
      const [intervalId, setIntervalId] = useState();
    
      const updateTimer = () => {
        setTime((prev) => {
          let newTime = { ...prev };
          // update sec and see if we need to increase min
          if (newTime.sec < 59) newTime.sec += 1;
          else {
            newTime.min += 1;
            newTime.sec = 0;
          }
          // min has increased in *newTime* by now if it was updated, see if it has crossed 59
          if (newTime.min === 60) {
            newTime.min = 0;
            newTime.hr += 1;
          }
    
          return newTime;
        });
      };
    
      const pauseOrResume = () => {
        if (!intervalId) {
          let id = setInterval(updateTimer, 1000);
          setIntervalId(id);
        } else {
          clearInterval(intervalId);
          setIntervalId("");
        }
      };
    
      const reset = () => {
        clearInterval(intervalId);
        setTime({
          sec: 0,
          min: 0,
          hr: 0
        });
      }; 
    
    const getImages = (id) =>{
        setImageLoading(true)
        TrackRep.getTracks(id).then(response => {
            if (response) {
              setImageLoading(false)
              if (response?.error) {
                sendNotify('error',response?.message);
              } else {
                // console.log(response);
                sendNotify('success', response?.message);
                setDocData(response?.data)
                let faData = response?.data?.fraud_assesment
                let arr = []

                setUnsupported(faData?.unsupported_documents)
                setLanguage(faData?.language)
                setIqFail(faData?.iq_fail)
                setEscalate(faData?.escalate)

                if (faData?.conculsive_quality.length > 0) {
                    arr.push('CQ')
                    conclusiveOnChange(faData?.conculsive_quality)
                }
                if (faData?.ODP) {
                    arr.push('ODP')
                    odpOnChange(faData?.ODP)
                }
                if (faData?.face_detection) {
                    arr.push('FD')
                    toggleFlag('FD')
                }
                if (faData?.picture_face_integrity) {
                    arr.push('PFI')
                    toggleFlag('PFI')
                }
                if (faData?.security_features) {
                    arr.push('SF')
                    toggleFlag('SF')
                }
                if (faData?.shape_template) {
                    arr.push('ST')
                    toggleFlag('ST')
                }
                if (faData?.fonts) {
                    arr.push('F')
                    toggleFlag('F')
                }
                if (faData?.digital_tampering) {
                    arr.push('DT')
                    toggleFlag('DT')
                }
                setSelectedFlags(arr)
                setTime(response?.data?.fa_time ?? {
                    sec: 0,
                    min: 0,
                    hr: 0
                  })
                pauseOrResume()
              }
            }
          })
        .catch(error => {
            sendNotify('error',error)
        })
    }

    const sampleDocVersions = [
        {
            document: Doc2012,
            year: '2012'
        },
        {
            document: SampleDocFront,
            year: '2016'
        },
        {
            document: Doc2020,
            year: '2020'
        },
    ]

    docRef.current?.addEventListener('wheel', function (event) { 
        // Zoom in or out based on the scroll direction 
        let direction = event.deltaY > 0 ? -1 : 1;
        if (direction == 1) {
            editImg('zoomIn')
        }else{
            editImg('zoomOut')
        }
    });

    sampleDocRef.current?.addEventListener('wheel', function (event) { 
        // Zoom in or out based on the scroll direction
        let direction = event.deltaY > 0 ? -1 : 1;
        if (direction == 1) {
            editSampleImg('zoomIn')
        }else{
            editSampleImg('zoomOut')
        }
    }); 

    const getChange = (key, value) => {
        let obj = {...formData}
        obj[key] = value
        setFormData(obj)
    };

    function editImg(action) {
        let style = {...imgStyle}
        switch (action) {
            case 'rorateX':
                style['rotate'] -= 45
                break;
            case 'rorateY':
                style['rotate'] += 45
                break;
            case 'zoomIn':
                if (style['zoom'] !== 10) {
                    style['zoom'] += 0.5
                }
                break;
            case 'zoomOut':
                if (style['zoom'] !== 0.5) {
                    style['zoom'] -= 0.5
                }
                break;
            case 'flip':
                style['flip'] = !style['flip']
                break;
        }

        setImgStyle(style)
    }

    function editSampleImg(action) {
        let style = {...sampleImgStyle}
        switch (action) {
            case 'rorateX':
                style['rotate'] -= 45
                break;
            case 'rorateY':
                style['rotate'] += 45
                break;
            case 'zoomIn':
                if (style['zoom'] !== 10) {
                    style['zoom'] += 0.5
                }
                break;
            case 'zoomOut':
                if (style['zoom'] !== 0.5) {
                    style['zoom'] -= 0.5
                }
                break;
            case 'flip':
                style['flip'] = !style['flip']
                break;
        }

        setSampleImgStyle(style)
    }

    const copyDocLink = () => {
        navigator.clipboard.writeText(currentUrl)
        setIsCopied(true)
    }

    const conclusiveOptions = [
        {
            label: 'Obscured Data Points',
            value: 'Obscured Data Points',
        },
        {
            label: 'Obscured Security Features',
            value: 'Obscured Security Features',
        },
        {
            label: 'Abnormal Doc Features',
            value: 'Abnormal Doc Features',
        },
        {
            label: 'Digital Text Overlay',
            value: 'Digital Text Overlay',
        },
        {
            label: 'Corner Physically Removed',
            value: 'Corner Physically Removed',
        },
        {
            label: 'Punctured',
            value: 'Punctured',
        },
        {
            label: 'Digital Version',
            value: 'Digital Version',
        },
        {
            label: 'Missing Back',
            value: 'Missing Back',
        },
        {
            label: 'Expired Document',
            value: 'Expired Document',
        },
    ];

    const odpOptions = [
        {
          label: 'Photo of a screen',
          value: 'Photo of a screen',
        },
        {
          label: 'Screenshot',
          value: 'Screenshot',
        },
        {
          label: 'Document printed on paper',
          value: 'Document printed on paper',
        },
        {
          label: 'Scan',
          value: 'Scan',
        },
    ];

    const leaveCP = [
        {
          label: 'Break',
          value: '1',
        },
        {
          label: 'Training',
          value: '2',
        },
        {
          label: 'Meeting',
          value: '3',
        },
        {
          label: <span onClick={()=>setShowComment(true)}>Others</span>,
          value: '4',
        },
    ];

    const conclusiveOnChange = (value) =>{
        onChangeFlag(value, 'CQ')
        setSelectConclusive(value)
    }

    const odpOnChange = (value) =>{
        onChangeFlag(value, 'ODP')
        setSelectODP(value)
    }

    const onChangeFlag = (value, label) =>{
        let arr = [...selectedFlags]
        let index = arr.findIndex(a => a == label)
        if (value.length == 0) {
            arr.splice(index, 1);
            if (label == 'CQ') {
                setDisableId(0)
            }
        } else {
            if (index == -1) {
                arr.push(label)
                if (label == 'CQ') {
                    setDisableId(2)
                }
            }
        }
        setSelectedFlags(arr)
    }

    const changeDocVersion = (e)=>{
        setSelectedDocVersion(e.target.value)
    }

    const onChange = (e) =>{
        console.log(e);
    }

    const confirm = (e) => {
        console.log(e);
    };

    const stateValidation = () =>{
        if (!formData['county']) {
            sendNotify('warning', 'Please select the county first.')
        }else{
            if (state.length == 0) {
                sendNotify('warning', 'No state is available in the selected county.')
            }
        }
    }

    let docVersions = sampleDocVersions.map((sdv, index)=>{
        return({
            value: index,
            label: <div className='version-doc'><img src={sdv.document} /><h6>{sdv.year}</h6></div>
        })
    })

    const unsupportedOptions = [
        {
          label: 'Document not supported',
          value: 'Document not supported',
        },
        {
          label: 'Template not available in the confluence',
          value: 'Template not available in the confluence',
        },
    ];

    const iqFailOptions = [
        {
          label: 'Blurred Data Points to Extract',
          value: 'Blurred Data Points to Extract',
        },
        {
          label: 'Glare on Data Points to Extract',
          value: 'Glare on Data Points to Extract',
        },
        {
          label: 'Dark Data Points to Extract',
          value: 'Dark Data Points to Extract',
        },
        {
            label: 'Covered Data Points to Extract',
            value: 'Covered Data Points to Extract',
        },
        {
            label: 'Cut off Data Points to Extract',
            value: 'Cut off Data Points to Extract',
        },
        {
            label: 'Damage on Data Points to Extract',
            value: 'Damage on Data Points to Extract',
        },
        {
            label: 'Other Issues with Data Points to Extract',
            value: 'Other Issues with Data Points to Extract',
        },
        {
            label: 'Missing Front',
            value: 'Missing Front',
        },
        {
            label: 'No Document in Image',
            value: 'No Document in Image',
        },
        {
            label: 'Two Documents Uploaded',
            value: 'Two Documents Uploaded',
        },
    ];

    const languageOptions = [
        {
          label: 'Latin',
          value: 'Latin',
        },
        {
          label: 'Japaneese',
          value: 'Japaneese',
        },
        {
          label: 'Chinese',
          value: 'Chinese',
        },
        {
            label: 'Cyrillic',
            value: 'Cyrillic',
        },
        {
            label: 'Vietnamese',
            value: 'Vietnamese',
        },
        {
            label: 'Korean',
            value: 'Korean',
        }
    ];

    const escalateOptions = [
        {
          label: 'Application error',
          value: 'Application error',
        },
        {
          label: 'Others',
          value: 'Others',
        },
    ];

    const toggleFlag = (flag) =>{
        let arr = [...selectedFlags]
        let index = arr.findIndex(a => a === flag)
        if (index !== -1) {
            arr.splice(index, 1);
            let find = arr.filter(a => a !== 'FD')
            if (find.length === 0) {
                setDisableId(0)
            }
        } else {
            arr.push(flag)
            if (flag != 'FD') {
                setDisableId(1)
            }
        }
        setSelectedFlags(arr)
    }

    const removeFlag = (flag) =>{
        let arr = [...selectedFlags]
        let index = arr.findIndex(a => a === flag)
        arr.splice(index, 1);
        if (flag == 'CQ') {
            setDisableId(0)
            setSelectConclusive([])
        }
        if (flag == 'ODP') {
            setSelectODP('')
        }
        setSelectedFlags(arr)
    }

    const backToList = () =>{
        navigate(`/app/process/${id}`)
    }

      const [formFields, setFormFields] = useState([0,1,2,3,4])
      const [formValues, setFormValues] = useState([])
      const [dynamicFields, setDynamicFields] = useState([])

    const getFieldUpdate = (index, value) =>{
        let arr = [...formValues]
        arr[index] = value
        setFormValues(arr)
    }

    const addDynamicField = () =>{
        let arr = [...dynamicFields]
        arr.push(formFields.length + 1)
        setDynamicFields(arr)
    }

    const removeDynamicField = (index, dyIndex) =>{
        let arrFields = [...dynamicFields]
        let arrValues = [...formValues]
        arrFields.splice(index, 1)
        arrValues.splice(dyIndex, 1)
        setDynamicFields(arrFields)
        setFormValues(arrValues)
    }

    const onSubmit = () =>{
        console.log(formValues, 'form');
    }

  return (
    <section className='fa-section'>
      <div className="task">
        <div className="d-flex">
            <div className="w-5">
                <div className="d-flex flex-column justify-content-between h-100">
                    <div>
                        <Popover
                            title={'Versions'}
                            placement="right"
                            content={
                                <div>
                                    <Radio.Group className='d-flex flex-column' defaultValue={0} options={docVersions} onChange={changeDocVersion} />
                                </div>
                            }
                            onConfirm={confirm}
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='rounded-btn'><i className="fas fa-folder-open"></i></button>
                        </Popover>
                        <button className='rounded-btn'><i className="fas fa-info"></i></button>
                    </div>
                    <div>
                        <Popover
                            title={'Unsupported Documents'}
                            placement="right"
                            content={
                                <div>
                                    <Radio.Group className='d-flex flex-column' value={unsupported} options={unsupportedOptions} onChange={(e)=>setUnsupported(e.target.value)} />
                                </div>
                            }
                            onConfirm={confirm}
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='rounded-btn'><i className="fas fa-flag-alt"></i></button>
                        </Popover>
                        <Popover
                            title={'Image Quality Fail'}
                            placement="right"
                            content={
                                <div>
                                    <Radio.Group className='d-flex flex-column' value={iqFail} options={iqFailOptions} onChange={(e)=>setIqFail(e.target.value)} />
                                </div>
                            }
                            onConfirm={confirm}
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='rounded-btn'><i className="fas fa-exclamation"></i></button>
                        </Popover>
                        <Popover
                            title={'Language'}
                            placement="right"
                            content={
                                <div>
                                    <Radio.Group className='d-flex flex-column' value={language} options={languageOptions} onChange={(e)=>setLanguage(e.target.value)} />
                                </div>
                            }
                            onConfirm={confirm}
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='rounded-btn'><i className="fas fa-comment"></i></button>
                        </Popover>
                        <Popover
                            title={'Escalate'}
                            placement="right"
                            content={
                                <div>
                                    <Radio.Group className='d-flex flex-column' value={escalate} options={escalateOptions} onChange={(e)=>setEscalate(e.target.value)} />
                                </div>
                            }
                            onConfirm={confirm}
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='rounded-btn'><i className="fas fa-arrow-up"></i></button>
                        </Popover>
                    </div>
                    <div>
                        
                    </div>
                </div>
            </div>
            <div className="w-50 me-2">
                        <div className="task-header">
                            <h4>Simman - Address Extraction</h4>
                            <h4>Priority</h4>
                        </div>
                        <div className="task-card">
                                <p className='task-timer'>{`${time.hr < 10 ? 0 : ""}${time.hr}:${time.min < 10 ? 0 : ""}${time.min}:${time.sec < 10 ? 0 : ""}${time.sec}`}</p>
                                <div className='process-count'>
                                    <p>Process Count:</p>
                                    <h6>02</h6>
                                </div>
                                <div className="w-75 mx-auto px-4 text-center">
                                    <div className="data-list">
                                        <h6><span>Country:</span> {docData?.issue_country ?? 'India'}</h6>
                                        <h6><span>Document Type:</span> {docData?.document_type ?? 'Aadhar'}</h6>
                                        <h6><span>Issuing State/Region:</span> {docData?.region ?? 'Tamil Nadu'}</h6>
                                    </div>
                                    <Draggable position={null}>
                                        <div>
                                            {imageLoading ? 
                                                <Skeleton.Image active className='custom-image-loader bg-white' />
                                                :
                                                <div ref={docRef} className={`doc-img ${imgStyle.flip ? 'flip' : ''}`} style={{rotate: `${imgStyle.rotate}deg`, transform: `scale(${imgStyle.zoom})`}}>
                                                    {/* <img className='back' src={`data:image/jpeg;base64,${docData?.image_back}`} alt="" />
                                                    <img className='front' src={`data:image/jpeg;base64,${docData?.image_front}`} alt="" /> */}
                                                    <img className='back' src={DocBack} alt="" />
                                                    <img className='front' src={DocFront} alt="" />
                                                </div>
                                            }
                                        </div>
                                    </Draggable>
                                    <div className="options">
                                        <div className="img-options">
                                            <button onClick={()=>editImg('rorateX')}><i className="fas fa-undo"></i></button>
                                            <button onClick={()=>editImg('zoomIn')}><i className="far fa-search-plus"></i></button>
                                            <button onClick={()=>editImg('flip')}><i className="far fa-window-restore"></i></button>
                                            <button onClick={()=>editImg('zoomOut')}><i className="far fa-search-minus"></i></button>
                                            <button onClick={()=>editImg('rorateY')}><i className="fas fa-redo-alt"></i></button>
                                        </div>
                                        <div className='doc-link'>
                                            <p>{currentUrl}</p>
                                            <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
                                                <button onClick={copyDocLink}><i className={`${isCopied ? 'fad' : 'far'} fa-copy`}></i></button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                        </div>
            </div>
            <div className="w-50 ms-2">
                <div className="d-flex justify-content-end mb-2">
                    <img src={Logo} className="task-logo" alt="Logo" />
                </div>
                <div className="task-options">
                    <div className="fields-wrapper">
                        <h3>Extract Data points from the document</h3>
                        {formFields.length > 0 && formFields.map((field, index) =>{
                            return(
                                <div className="data-input" key={index}>
                                    <div className='w-100 me-3 pe-1'>
                                        <label htmlFor={index}>Address Line {index+1}</label>
                                        <input type="text" id={index} name={index} onChange={(e)=>getFieldUpdate(index, e.target.value)} value={formValues[index]} />
                                    </div>
                                    <button className='ms-5' onClick={()=>getFieldUpdate(index, 'Not Data')}><i className="far fa-minus-circle"></i></button>
                                </div>
                            )
                        })}
                        {dynamicFields.length > 0 && dynamicFields.map((field, index) =>{
                            let dyIndex = index + formFields.length
                            return(
                                <div className="data-input" key={index}>
                                    <div className='w-100 me-3 pe-1'>
                                        <label htmlFor={index}>Address Line {(index+1) + formFields.length}</label>
                                        <input type="text" id={index} name={index} onChange={(e)=>getFieldUpdate(dyIndex, e.target.value)} value={formValues[dyIndex]} />
                                    </div>
                                    <div className='d-flex'>
                                        <button className='me-3' onClick={()=>removeDynamicField(index, dyIndex)}><i className="far fa-trash-alt"></i></button>
                                        <button onClick={()=>getFieldUpdate(dyIndex, 'Not Data')}><i className="far fa-minus-circle"></i></button>
                                    </div>
                                </div>
                            )
                        })}
                        <button className='update-btn' onClick={addDynamicField}><i className="far fa-plus-circle"></i> Add one more line</button>
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                            <Popover
                                title="Leave CP"
                                placement="left"
                                content={
                                    <div>
                                        <Radio.Group className='d-flex flex-column' options={leaveCP} onChange={onChange} />
                                    </div>
                                }
                                onConfirm={confirm} 
                                okText="Submit"
                                cancelText="Cancel"
                            >
                                <button className='leave-btn me-2'>Leave CP</button>
                            </Popover>
                            <button className={`submit-btn ms-2 ${loading ? 'btn-loading' : ''}`} onClick={onSubmit}>
                                <i className={`far ${loading ? 'fa-spinner-third' : 'fa-paper-plane'}`}></i>
                                &nbsp;Submit</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Modal title="Other" className='custom-modal' open={showComment} width={500} centered onOk={()=>setShowComment(false)} okText={'Save'} onCancel={()=>setShowComment(false)}>
            <div className="input-box">
            <label htmlFor="comments">Comments:</label>
            <textarea name="comments" id='comments' placeholder='Enter you comments' className='w-100' rows="5"></textarea>
            </div>
      </Modal>
    </section>
  )
}