import React from 'react'

import NotFoundImg from '../assets/img/not-found.png'

export default function NotFound() {
  return (
    <div className='not-found'>
        <img src={NotFoundImg} alt="Not Found" />
    </div>
  )
}
