import React from 'react'
import { Outlet } from 'react-router-dom'

export default function Index() {

  return (
    <div>
      <div className='base-layout'>
        <Outlet />
      </div>
    </div>
  )
}
