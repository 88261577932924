import Repository, { apiUrl } from './index';

class TrackRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getDocStatus(trackID) {
        let url = `${apiUrl}/track/${trackID}`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }
    async getTracks() {
        let url = `${apiUrl}/alltracks`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }
    async getProcessedData(id, status) {
        let url = `${apiUrl}/getImage/${status}/${id}`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }
    async applyManual(payload) {
        let url = `${apiUrl}/manual-approve`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }
    async sendMail(payload) {
        let url = `${apiUrl}/sendMail`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }
}

export default new TrackRepository();