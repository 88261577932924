import React, { useState } from 'react'
import { Checkbox } from 'antd'


export default function DlForm(props) {
    let { inputSchema } = props;

    const [formValues, setFormValues] = useState([])
    const [dynamicFields, setDynamicFields] = useState([])

    const getFieldUpdate = (index, value) => {
        let arr = [...formValues]
        arr[index] = value
        setFormValues(arr)
    }

    const addDynamicField = () => {
        let arr = [...dynamicFields]
        arr.push('Label')
        setDynamicFields(arr)
    }

    const updateLabelName = (value, index) => {
        let arr = [...dynamicFields]
        arr[index] = value
        setDynamicFields(arr)
    }

    const removeDynamicField = (index, dyIndex) => {
        let arrFields = [...dynamicFields]
        let arrValues = [...formValues]
        arrFields.splice(index, 1)
        arrValues.splice(dyIndex, 1)
        console.log(arrValues, 'value');
        setDynamicFields(arrFields)
        setFormValues(arrValues)
    }

    const onChange = (e) => {
        console.log(e);
    }

    return (
        <div>{
            inputSchema.length > 0 && inputSchema.map((field, index) => {
                return (
                    <div className='dl-row grid-4 mb-3' key={index}>
                        <div className='d-flex align-items-center'><Checkbox onChange={onChange}><span className='label'>{field}</span></Checkbox></div>
                        <div className="data-input mb-0"><input type="text" id={index} name={index} onChange={(e) => getFieldUpdate(index, e.target.value)} /></div>
                        <div className="data-input mb-0"><input type="text" id={index} name={index} onChange={(e) => getFieldUpdate(index, e.target.value)} /></div>
                        <div className="data-input mb-0"><input type="text" id={index} name={index} onChange={(e) => getFieldUpdate(index, e.target.value)} /></div>
                    </div>
                )
            })}
            <button className='update-btn mb-3' onClick={addDynamicField}><i className="far fa-plus-circle"></i> Add another row</button>
            {dynamicFields.length > 0 && dynamicFields.map((field, index) => {
                return (
                    <div className='dl-row grid-4 mb-3' key={index}>
                        <div className='d-flex align-items-center'><Checkbox onChange={onChange}><span className='label' contentEditable={true} onBlur={(e) => updateLabelName(e.target.innerText, index)}>{field}</span></Checkbox></div>
                        <div className="data-input mb-0"><input type="text" id={index} name={index} onChange={(e) => getFieldUpdate(index, e.target.value)} /></div>
                        <div className="data-input mb-0"><input type="text" id={index} name={index} onChange={(e) => getFieldUpdate(index, e.target.value)} /></div>
                        <div className="data-input mb-0"><input type="text" id={index} name={index} onChange={(e) => getFieldUpdate(index, e.target.value)} /></div>
                        <button className='update-btn' onClick={() => removeDynamicField(index, index + inputSchema.length)}><i className="far fa-trash-alt"></i></button>
                    </div>
                )
            })
            }</div>
    )
}
