// Auth
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";

//Private
import Dashboard from "../pages/dashboard";
import Classification from "../pages/classification";
import FraudAssessment from "../pages/fraud-assessment";
import Extraction from "../pages/extraction";
import AddressEx from "../pages/address-ex";
import DLExtraction from "../pages/dl-extraction";
import QcAnalyst from "../pages/qc-analyst";

import NotFound from "../pages/not-found";

const routes = [
  {
    name: "Login",
    path: "/",
    component: <Login />,
    type: "public",
    permissions: [],
  },
  {
    name: "Login",
    path: "/login",
    component: <Login />,
    type: "public",
    permissions: [],
  },
  {
    name: "Register",
    path: "/register",
    component: <Register />,
    type: "public",
    permissions: [],
  },
  {
    name: "Dashboard",
    path: "dashboard",
    component: <Dashboard />,
    type: "private",
    permissions: ["SA"],
  },
  {
    name: "Classification",
    path: "classification",
    component: <Classification />,
    type: "private",
    permissions: ["F"],
  },
  {
    name: "Fraud Assessment",
    path: "fraud-assessment",
    component: <FraudAssessment />,
    type: "private",
    permissions: ["F"],
  },
  {
    name: "Extraction",
    path: "extraction",
    component: <Extraction />,
    type: "private",
    permissions: ["F"],
  },
  {
    name: "Address Extraction",
    path: "address-extraction",
    component: <AddressEx />,
    type: "private",
    permissions: ["F"],
  },
  {
    name: "Driving Information Extraction",
    path: "dl-extraction",
    component: <DLExtraction />,
    type: "private",
    permissions: ["F"],
  },
  {
    name: "QC Analyst Dashboard",
    path: "qc-analyst",
    component: <QcAnalyst />,
    type: "private",
    permissions: ["F"],
  },
  {
    name: "Error 404",
    path: "*",
    component: <NotFound />,
    type: "public",
  },
];

export default routes;
