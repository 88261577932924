import React, { useState } from 'react'
import { Table, DatePicker, Tabs, Popover, Radio, Modal } from 'antd';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'

import Logo from '../assets/img/logo-with-text.png'

import { sendNotify } from '../helper/notification'
import { checkLogged } from '../store/auth/authSlice'

const { RangePicker } = DatePicker;

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

ChartJS.defaults.color = "#ffffff";
ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.family = "Comfortaa, cursive";

function QcAnalyst() {
    const dispatch = useDispatch()
    const [showComment, setShowComment] = useState(false);
    const [comment, setComment] = useState('');

    const pendingColumns = [
        {
            title: 'Row',
            dataIndex: 'row',
        },
        {
            title: 'Assigned Date',
            dataIndex: 'assignedDate',
            width: 160,
        },
        {
            title: 'Assigned Time',
            dataIndex: 'assignedTime',
            width: 160,
        },
        {
            title: 'Report ID',
            dataIndex: 'reportId',
        },
        {
            title: 'Task ID',
            dataIndex: 'taskId',
        },
        {
            title: 'Client',
            dataIndex: 'client',
        },
        {
            title: 'Task Type',
            dataIndex: 'taskType',
        },
        {
            title: 'Processed By',
            dataIndex: 'processedBy',
            width: 160,
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
        },
        {
            title: 'Task Assigned By',
            dataIndex: 'assignedBy',
            width: 200,
        },
        {
            title: 'Action',
            fixed: 'right',
            width: 150,
            render: (_, { taskId }) => (
                <button className='action-btn' onClick={() => console.log(taskId)}>Review</button>
            ),
        },
    ];
    const completedColumns = [
        {
            title: 'Row',
            dataIndex: 'row',
        },
        {
            title: 'Assigned Date',
            dataIndex: 'assignedDate',
            width: 160,
        },
        {
            title: 'Assigned Time',
            dataIndex: 'assignedTime',
            width: 160,
        },
        {
            title: 'Report ID',
            dataIndex: 'reportId',
        },
        {
            title: 'Task ID',
            dataIndex: 'taskId',
        },
        {
            title: 'Client',
            dataIndex: 'client',
        },
        {
            title: 'Task Type',
            dataIndex: 'taskType',
        },
        {
            title: 'Processed By',
            dataIndex: 'processedBy',
            width: 160,
        },
        {
            title: 'QC Completed Time',
            dataIndex: 'qcCompletedTime',
            width: 200,
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
        },
        {
            title: 'Task Assigned By',
            dataIndex: 'assignedBy',
            width: 200,
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 150,
            render: (_, { taskId }) => (
                <button className='action-btn' onClick={() => console.log(taskId)}>Review</button>
            ),
        },
    ];

    const pendingData = [
        {
            key: '1',
            row: 1,
            assignedDate: '23/04/23',
            assignedTime: '05:00PM',
            reportId: '893234',
            taskId: '903243',
            client: 'XYZ',
            taskType: 'FA',
            processedBy: 'Harish',
            duration: '30min 05sec',
            assignedBy: 'Simman',
        },
        {
            key: '2',
            row: 2,
            assignedDate: '23/04/23',
            assignedTime: '05:00PM',
            reportId: '893234',
            taskId: '903243',
            client: 'XYZ',
            taskType: 'FA',
            processedBy: 'Harish',
            duration: '30min 05sec',
            assignedBy: 'Simman',
        },
        {
            key: '3',
            row: 3,
            assignedDate: '23/04/23',
            assignedTime: '05:00PM',
            reportId: '893234',
            taskId: '903243',
            client: 'XYZ',
            taskType: 'FA',
            processedBy: 'Harish',
            duration: '30min 05sec',
            assignedBy: 'Simman',
        },
        {
            key: '4',
            row: 4,
            assignedDate: '23/04/23',
            assignedTime: '05:00PM',
            reportId: '893234',
            taskId: '903243',
            client: 'XYZ',
            taskType: 'FA',
            processedBy: 'Harish',
            duration: '30min 05sec',
            assignedBy: 'Simman',
        },
        {
            key: '5',
            row: 5,
            assignedDate: '23/04/23',
            assignedTime: '05:00PM',
            reportId: '893234',
            taskId: '903243',
            client: 'XYZ',
            taskType: 'FA',
            processedBy: 'Harish',
            duration: '30min 05sec',
            assignedBy: 'Simman',
        },
    ];
    const completedData = [
        {
            key: '1',
            row: 1,
            assignedDate: '26/04/23',
            assignedTime: '03:00PM',
            reportId: '902432',
            taskId: '123452',
            client: 'XYZ',
            taskType: 'Class',
            processedBy: 'Nazeer',
            qcCompletedTime: '10mins 12sec',
            duration: '24mis 15sec',
            assignedBy: 'Simman',
        },
        {
            key: '2',
            row: 2,
            assignedDate: '26/04/23',
            assignedTime: '03:00PM',
            reportId: '902432',
            taskId: '123452',
            client: 'XYZ',
            taskType: 'Class',
            processedBy: 'Nazeer',
            qcCompletedTime: '10mins 12sec',
            duration: '24mis 15sec',
            assignedBy: 'Simman',
        },
        {
            key: '3',
            row: 3,
            assignedDate: '26/04/23',
            assignedTime: '03:00PM',
            reportId: '902432',
            taskId: '123452',
            client: 'XYZ',
            taskType: 'Class',
            processedBy: 'Nazeer',
            qcCompletedTime: '10mins 12sec',
            duration: '24mis 15sec',
            assignedBy: 'Simman',
        },
        {
            key: '4',
            row: 4,
            assignedDate: '26/04/23',
            assignedTime: '03:00PM',
            reportId: '902432',
            taskId: '123452',
            client: 'XYZ',
            taskType: 'Class',
            processedBy: 'Nazeer',
            qcCompletedTime: '10mins 12sec',
            duration: '24mis 15sec',
            assignedBy: 'Simman',
        },
        {
            key: '5',
            row: 5,
            assignedDate: '26/04/23',
            assignedTime: '03:00PM',
            reportId: '902432',
            taskId: '123452',
            client: 'XYZ',
            taskType: 'Class',
            processedBy: 'Nazeer',
            qcCompletedTime: '10mins 12sec',
            duration: '24mis 15sec',
            assignedBy: 'Simman',
        },
        {
            key: '6',
            row: 6,
            assignedDate: '26/04/23',
            assignedTime: '03:00PM',
            reportId: '902432',
            taskId: '123452',
            client: 'XYZ',
            taskType: 'Class',
            processedBy: 'Nazeer',
            qcCompletedTime: '10mins 12sec',
            duration: '24mis 15sec',
            assignedBy: 'Simman',
        },
    ];


    const tabs = [
        {
            key: '1',
            label: 'Review Multiple (44)',
            children: <div>
                <div className="input-box qc-input">
                    <label htmlFor="" className='text-white'>Select task by: &nbsp;</label>
                    <div className="d-flex">
                        <input type="number" className='me-2' placeholder='From Row' />
                        <input type="number" placeholder='To Row' />
                    </div>
                    <button className='update-btn ms-3'>Review</button>
                </div>
                <Table className='custom-table' pagination={{ className: 'custom-pagination' }} columns={pendingColumns} dataSource={pendingData} />
            </div>,
        },
        {
            key: '2',
            label: 'Completed (56)',
            children: <div>
                <div className="input-box qc-input">
                    <label htmlFor="" className='text-white'>Select task by: &nbsp;</label>
                    <div className="d-flex">
                        <input type="number" className='me-2' placeholder='From Row' />
                        <input type="number" placeholder='To Row' />
                    </div>
                    <button className='update-btn ms-3'>Review</button>
                </div>
                <Table className='custom-table' pagination={{ className: 'custom-pagination' }} columns={completedColumns} dataSource={completedData} />
            </div>,
        },
    ];

    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                color: 'white'
            },
            legend: {
                position: 'top',
            },
            title: {
                display: false
            },
        },
    };

    const labels = ['01-11-2023', '02-11-2023', '03-11-2023', '04-11-2023', '05-11-2023', '06-11-2023'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Completed',
                data: [300, 200, 400, 300, 200, 400],
                backgroundColor: 'rgba(20, 168, 0, 0.9)',
            },
            {
                label: 'Pending',
                data: [500, 400, 500, 400, 300, 500],
                backgroundColor: 'rgba(238, 115, 15, 0.9)',
            },
        ],
    };

    const logout = () => {
        sendNotify('success', 'Logout successfully')
        localStorage.removeItem('nl-consular-auth')
        dispatch(checkLogged())
    }

    const leaveCP = [
        {
            label: 'Break',
            value: '1',
        },
        {
            label: 'Training',
            value: '2',
        },
        {
            label: 'Meeting',
            value: '3',
        },
        {
            label: <span onClick={() => setShowComment(true)}>Others</span>,
            value: '4',
        },
    ];

    const confirm = (e) => {
        console.log(e);
    };

    const onChange = (value) => {
        console.log(value);
    }

    return (
        <div className='task p-4'>
            <div className="task-header align-items-center">
                <h4>QC Dashboard</h4>
                <div className="d-flex">
                    <img src={Logo} className="task-logo" alt="Logo" />
                    <button className='logout ms-4' onClick={logout}><i className="far fa-power-off"></i></button>
                </div>
            </div>
            <div className="task-card dash-card d-block p-3 qc-dash">
                <div className="container-fluid">
                    <div className="row">
                        <h1>Welcome back, Harish</h1>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet.</p>
                        <div className="col-md-4 mt-3">
                            <div className="custom-card">
                                <div className="input-box">
                                    <label className='text-white' htmlFor="">Filter by date: &nbsp;</label>
                                    <DatePicker className='custom-datepicker' defaultValue={dayjs('30/11/2023', "DD/MM/YYYY")} />
                                </div>
                                <div className="flex-box">
                                    <label>Task Assigned By</label>
                                    <h6>Simman</h6>
                                </div>
                                <div className="flex-box">
                                    <label>Assigned Time</label>
                                    <h6>10:00AM</h6>
                                </div>
                                <div className="flex-box">
                                    <label>Number of Task Assigned</label>
                                    <h6>100</h6>
                                </div>
                                <div className="flex-box">
                                    <label>Number of Task Completed</label>
                                    <h6>56</h6>
                                </div>
                                <div className="flex-box mb-0">
                                    <label>Number of Task Pending</label>
                                    <h6>44</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 mt-3">
                            <div className="custom-card py-4">
                                <div className="input-box">
                                    <label className='text-white' htmlFor="">Filter by date: &nbsp;</label>
                                    <RangePicker className='custom-datepicker' />
                                </div>
                                <Bar options={options} data={data} />
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="d-flex flex-column h-100 align-items-end justify-content-between">
                                <div className="custom-card w-100">
                                    <table width={'100%'}>
                                        <tbody>
                                            <tr><td><b>On CP Hours</b></td><th>07:00 hr</th></tr>
                                            <tr>
                                                <td><b className='cus-pointer'><Popover
                                                    title="Total"
                                                    placement="bottom"
                                                    content={
                                                        <table width={'100%'} className='break-table'>
                                                            <tr>
                                                                <td><b>Break</b></td>
                                                                <th>00:30 hr</th>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Training</b></td>
                                                                <th>00:10 hr</th>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Meeting</b></td>
                                                                <th>00:10 hr</th>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Others</b></td>
                                                                <th>00:00 hr</th>
                                                            </tr>
                                                        </table>
                                                    }
                                                    onConfirm={confirm}
                                                    okText="Submit"
                                                    cancelText="Cancel"
                                                >
                                                    Total Break Time <i class="fal fa-info-circle"></i>
                                                </Popover></b></td>
                                                <th>00:50 hr
                                                </th>
                                            </tr>
                                            <tr><td><b>Idle Time</b></td><th>00:10 hr</th></tr>
                                        </tbody>
                                    </table>
                                    <Popover
                                        title="Leave CP"
                                        placement="left"
                                        content={
                                            <div>
                                                <Radio.Group className='d-flex flex-column' options={leaveCP} onChange={onChange} />
                                            </div>
                                        }
                                        onConfirm={confirm}
                                        okText="Submit"
                                        cancelText="Cancel"
                                    >
                                        <button className='update-btn mt-3 ms-auto'>Break</button>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="task-card d-block mt-3 p-3 qc-dash">
                <Tabs className='custom-tabs' defaultActiveKey="1" items={tabs} />
            </div>
            <Modal title="Other" className='custom-modal' open={showComment} width={500} centered onOk={() => setShowComment(false)} okText={'Save'} onCancel={() => setShowComment(false)}>
                <div className="input-box">
                    <label htmlFor="comments">Comments:</label>
                    <textarea name="comments" id='comments' placeholder='Enter you comments' className='w-100' rows="5"></textarea>
                </div>
            </Modal>
        </div>
    );
}

export default QcAnalyst;