import React, { useState } from 'react'
export default function CustomDlForm(props) {
    let { inputSchema } = props;

    const [formSchema, setFormSchema] = useState(inputSchema)
    const [formValues, setFormValues] = useState([])

    const getFieldUpdate = (index, value) => {
        let arr = [...formValues]
        arr[index] = value
        setFormValues(arr)
    }

    const addCat = () => {
        let form = [...formSchema]

        form.push({
            label: '(C)',
            sub: [
                {
                    label: '(S)',
                    row: [[]]
                }
            ]
        },)
        setFormSchema(form)
    }

    const updateCatName = (value, index) => {
        let arr = [...formSchema]
        arr[index].label = value
        setFormSchema(arr)
    }

    const removeCat = (index) => {
        let form = [...formSchema]

        form.splice(index, 1)
        setFormSchema(form)
    }

    const addSubCat = (cat) => {
        let form = [...formSchema]
        form.find(f => f.label == cat)?.sub.push({
            label: '(S)',
            row: [[]]
        })
        setFormSchema(form)
    }

    const updateSubName = (value, catIndex, index) => {
        let arr = [...formSchema]
        arr[catIndex].sub[index].label = value
        setFormSchema(arr)
    }

    const addRow = (cat, subCat) => {
        let form = [...formSchema]
        form[cat]?.sub[subCat]?.row.push([])
        setFormSchema(form)
    }

    const removeRow = (cat, subCat, index) => {
        // console.log(cat, subCat, index);
        let form = [...formSchema]
        form[cat]?.sub[subCat]?.row.splice(index, 1)

        if (form[cat]?.sub[subCat].row.length == 0) {
            form[cat]?.sub.splice(subCat, 1)
            if (form[cat]?.sub.length === 0) {
                form.splice(cat, 1)
            }
        }

        console.log(form);
        setFormSchema(form)
    }

    return (
        <div className='custom-form'>
            <div className="dl-wrapper paper-dl">
                <div className="dl-header d-flex mb-2">
                    <h6 className='me-3'>Category</h6>
                    <h6 className='me-3'>Sub Cat</h6>
                    <h6 className='me-3'>Obtained Date</h6>
                    <h6 className='me-3'>Expiration Date</h6>
                    <h6 className='me-3'>Optional Date</h6>
                </div>
                {formSchema?.length > 0 && formSchema?.map((field, index) => {
                    return (
                        <div className="d-flex align-items-center mb-3" key={index}>
                            <div className='dl-row d-flex align-items-center'>
                                <h2 className='cat-text' contentEditable={true} onBlur={(e) => updateCatName(e.target.innerText, index)}>{field?.label}</h2>
                                <div className=''>
                                    {field?.sub?.map((sub, idx) => {
                                        return (
                                            <div className='d-flex align-items-start my-3' key={idx}>
                                                <h5 className='sub-cat-text mt-4' contentEditable={true} onBlur={(e) => updateSubName(e.target.innerText, index, idx)}>{sub?.label}</h5>
                                                <div>
                                                    {sub?.row?.length > 0 && sub?.row?.map((row, i) => {
                                                        return (
                                                            <div className='grid-row mb-2' key={i}>
                                                                <div className="data-input mb-0"><input type="text" id={i} name={i} onChange={(e) => getFieldUpdate(i, e.target.value)} /></div>
                                                                <div className="data-input mb-0"><input type="text" id={i} name={i} onChange={(e) => getFieldUpdate(i, e.target.value)} /></div>
                                                                <div className="data-input mb-0"><input type="text" id={i} name={i} onChange={(e) => getFieldUpdate(i, e.target.value)} /></div>
                                                                <button className='update-btn' onClick={() => removeRow(index, idx, i)}><i className="far fa-trash-alt"></i></button>
                                                            </div>
                                                        )
                                                    })}
                                                    <button className='update-btn mb-3' onClick={() => addRow(index, idx)}><i className="far fa-plus-circle"></i> Add another row</button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <button className='update-btn mb-3' onClick={() => addSubCat(field?.label)}><i className="far fa-plus-circle"></i> Add another sub category</button>
                                </div>
                            </div>
                            <button className='update-btn ms-2' onClick={() => removeCat(index)}><i className="far fa-trash-alt"></i></button>
                        </div>
                    )
                })}
                <button className='update-btn mb-3' onClick={addCat}><i className="far fa-plus-circle"></i> Add another category</button>
            </div>
        </div>
    )
}