import Repository, { apiUrl } from './index';

class LoginRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async login(payload) {
        let url = `${apiUrl}/login`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }
    async signup(payload) {
        let url = `${apiUrl}/register`;
        const reponse = await Repository.post(url, payload)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return reponse;
    }

}

export default new LoginRepository();